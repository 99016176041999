import { 
  GET_CP_DOMAIN,
  GET_CP_DEVICE_INFO,
  GET_IS_RECAPTCHA_ENABLED,
  GET_DEVICE_ID,
  GET_JITSIMEET,
  GET_MEDIA_DEVICES,
  GET_PERMISSIONS,
  GET_SERVICE_REGISTRATION,
  GET_DEVICE_INFO,
  GET_CP_URL,
  GET_MS_ID,
  GET_IS_MAC,
  GET_HARMONY_ID,
} from "../constants/getters";
import { 
  SET_CP_DOMAIN,
  SET_CP_DEVICE_INFO,
  SET_JITSIMEET,
  SET_MEDIA_DEVICES,
  SET_PERMISSIONS,
  SET_SERVICE_REGISTRATION,
  SET_DEVICE_INFO,
  SET_CP_URL,
  SET_MS_ID,
  SET_HARMONY_ID,
} from "../constants/mutations";
import { 
  CENTERAL_POINT_URL_DOMAIN
} from "../constants/actions";
export default {
  state: {
    cp_domain: '',
    cp_url: '',
    mothership_id: '',
    harmony_id: '',
    cp_device_info: {},
    jitsimeet: {},
    media_devices: {},
    permissions: {},
    service_registration: {},
    device_info: {},
  },
  getters: {
    [GET_CP_DOMAIN](state){ return state.cp_domain },
    [GET_MS_ID](state){ return state.mothership_id },
    [GET_HARMONY_ID](state){ return state.harmony_id },
    [GET_CP_URL](state){ return state.cp_url },
    [GET_IS_RECAPTCHA_ENABLED](){
      if(process.env.NODE_ENV!='production') return false;
      else if(process.env.NODE_ENV=='production') return !!(process.env.VUE_APP_RECAPTCHA_SHOW_DOMAINS)?.split?.(',')?.includes?.(window.location.host);
      else return false;
    },
    [GET_CP_DEVICE_INFO](state){
      return {
        city: state.cp_device_info.city ?? '',
        country: state.cp_device_info.country ?? '',
        ip: state.cp_device_info.ip ?? '',
        platform: 'web',
        platform_version: '',
        os: state.cp_device_info.os ?? '',
        os_version: state.cp_device_info.os_version ?? '',
        browser: state.cp_device_info.browser ?? '',
        browser_versiion: state.cp_device_info.browser_versiion ?? '',
        name: '',
        device_id: state.device_info.id ?? state.cp_device_info.device_id ?? '',
        DNT: !!(window?.navigator?.doNotTrack || window?.doNotTrack || window?.navigator?.msDoNotTrack),
      }
    },
    [GET_DEVICE_ID](state,getters){ return getters[GET_CP_DEVICE_INFO].device_id },
    [GET_JITSIMEET](state){ return state.jitsimeet },
    [GET_DEVICE_INFO](state){ return state.device_info },
    [GET_IS_MAC](state){ return state.device_info.os.mac },
    [GET_MEDIA_DEVICES](state){ return state.media_devices },
    [GET_PERMISSIONS](state){ return state.permissions },
    [GET_SERVICE_REGISTRATION](state){ return state.service_registration },
  },
  actions: {
    [CENTERAL_POINT_URL_DOMAIN]({ commit, },data){
      if(data?.centeralpoint) commit(SET_CP_URL,data?.centeralpoint)
      if(data?.domain) commit(SET_CP_DOMAIN,data?.domain)
      commit(SET_MS_ID)
      commit(SET_HARMONY_ID)
    },
  },
  mutations: {
    [SET_CP_DOMAIN](state,value){
      state.cp_domain=value ?? ''
    },
    [SET_CP_URL](state,value){
      state.cp_url=value || ''
    },
    [SET_MS_ID](state,value){
      state.mothership_id=value || ''
    },
    [SET_HARMONY_ID](state,value){
      state.harmony_id=value || ''
    },
    [SET_CP_DEVICE_INFO](state,value){
      state.cp_device_info=value ?? ''
    },
    [SET_DEVICE_INFO](state,value){
      state.device_info=value ?? ''
    },
    [SET_JITSIMEET](state,value){ 
      state.jitsimeet=value 
    },
    [SET_MEDIA_DEVICES](state,value){ 
      state.media_devices=value 
    },
    [SET_PERMISSIONS](state,value){ 
      state.permissions=value 
    },
    [SET_SERVICE_REGISTRATION](state,value){ 
      state.service_registration=value 
    },
  },
};